
              @import "@/assets/css/variables.scss";
            





































.socialMedia {
  display: flex;

  .svg-icon {
    font-size: 24px;
    @include rtl-sass-prop(margin-right, margin-left, 16px);
    &:hover {
      color: $primary;
    }
  }
}
